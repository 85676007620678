import { ResidencyStatus } from "../services/apis/create-application.schema";

/**
 * Set of valid bridging visa subclass identifiers.
 * This includes numeric codes (with and without leading zeros) and text variations.
 */
export const BRIDGING_VISA_SUBCLASSES = new Set([
	// Bridging Visa A (BVA)
	"010", "10", "a", "A", "Bridging A", "bridging a", "BVA", "bva",
	// Bridging Visa B (BVB)
	"020", "20", "b", "B", "Bridging B", "bridging b", "BVB", "bvb",
	// Bridging Visa C (BVC)
	"030", "30", "c", "C", "Bridging C", "bridging c", "BVC", "bvc",
	// Bridging Visa E (BVE)
	"050", "50", "051", "51", "e", "E", "Bridging E", "bridging e", "BVE", "bve",
]);

export const APPROVED_PR_VISA = [100, 191, 103, 200, 111, 201, 114, 202, 115, 203, 116, 204, 119, 801, 120, 804, 121, 808, 124, 814, 132, 835, 134, 836, 136, 838, 137, 855, 138, 856, 139, 857, 143, 858, 151, 864, 155, 866, 157, 885, 173, 886, 175, 887, 176, 888, 186, 890, 187, 891, 189, 892, 190, 893];
export const APPROVED_TEMPORARY_VISA = [160, 423, 161, 428, 162, 444, 163, 457, 164, 461, 165, 475, 188, 476, 300, 482, 309, 485, 310, 487, 401, 491, 403, 494, 405, 500, 407, 590, 410, 790, 415, 820, 416, 870, 420, 884, 422];

/**
 * Checks if a given visa subclass is a bridging visa.
 *
 * This function handles various input formats:
 * - Numeric codes (e.g., 10, 20, 30, 50, 51)
 * - Numeric strings with leading zeros (e.g., "010", "020", "030", "050", "051")
 * - Single letters (e.g., "A", "B", "C", "E")
 * - Full names (e.g., "Bridging A", "Bridging B", "Bridging C", "Bridging E")
 * - Short codes (e.g., "BVA", "BVB", "BVC", "BVE")
 *
 * The check is case-insensitive and ignores leading/trailing whitespace.
 *
 * @param visaSubclass - The visa subclass to check. Can be a number, string, null, or undefined
 * @returns boolean - True if the visa subclass is a bridging visa, false otherwise
 *
 * @example
 * isBridgingVisa(10)           // true
 * isBridgingVisa("010")        // true
 * isBridgingVisa("BVA")        // true
 * isBridgingVisa("Bridging A") // true
 * isBridgingVisa("A")          // true
 * isBridgingVisa("100")        // false
 */
export function isBridgingVisa(visaSubclass: string | number | null | undefined): boolean {
	if (visaSubclass === null || visaSubclass === undefined) return false;
	if (typeof visaSubclass === 'string' && !visaSubclass.trim()) return false;

	const normalizedInput = String(visaSubclass).toLowerCase().trim();
	return BRIDGING_VISA_SUBCLASSES.has(normalizedInput);
}


/**
 * Checks if a given visa subclass is an approved PR visa.
 * @param visaSubclass - The visa subclass to check. Can be a number, string, null, or undefined
 * @returns boolean - True if the visa subclass is an approved PR visa, false otherwise
 */
export function isApprovedPRVisa(visaSubclass: string | number | null | undefined): boolean {
	return APPROVED_PR_VISA.includes(Number(visaSubclass));
}

/**
 * Checks if a given visa subclass is an approved temporary visa.
 * @param visaSubclass - The visa subclass to check. Can be a number, string, null, or undefined
 * @returns boolean - True if the visa subclass is an approved temporary visa, false otherwise
 */
export function isApprovedTemporaryVisa(visaSubclass: string | number | null | undefined): boolean {
	return APPROVED_TEMPORARY_VISA.includes(Number(visaSubclass));
}

/**
 * Check if a visa is valid based on residency, subclass and expected PR.
 * @param residencyStatus
 * @param visaSubclass
 * @param expectPRInThreeMonths
 */
export const isValidVisa = (residencyStatus?: string, visaSubclass?: string, expectPRInThreeMonths?: boolean) => {
	if (!residencyStatus) {
		return false;
	}
	if ([ResidencyStatus.AU, ResidencyStatus.NZ].includes(residencyStatus)) {
		return true;
	}
	if (!visaSubclass) {
		return false;
	}
	if (isApprovedPRVisa(visaSubclass)) {
		return true;
	}
	return (isBridgingVisa(visaSubclass) || isApprovedTemporaryVisa(visaSubclass)) && expectPRInThreeMonths;
};

/**
 * Check if a visa subclass is a valid temporary visa.
 * @param visaSubclass
 */
export const isValidTemporaryVisa = (visaSubclass?: string) =>
	isBridgingVisa(visaSubclass) || isApprovedTemporaryVisa(visaSubclass);
