import { type FC, type FormEvent, useEffect, useState } from "react";
import { liveInOptions } from "../../../../utils/BootList";
import {
	useAppSelector,
	useAppDispatch,
} from "../../../../../hooks/redux-hooks";
import RadioGroup from "../../../radio-group/RadioGroup";
import { useForm } from "react-hook-form";
import {
	setPropertyAddress,
	setPropertyAddressParts,
	setPropertyPurpose,
	setPropertyValuation,
	setIndicatedRentalIncomeForIP,
	setExistingMortgagedProperties,
	setWillLiveInExistingMortgagedProperty,
} from "../../../../../store/slices/form-slice";
import AddressSearch, {
	type Place,
} from "../../../address-search/AddressSearch";
import type { AddressParts } from "../../../../../services/apis/create-application.schema";
import DollarInput from "../../../dollar-input/DollarInput";
import Modal from "../../../modal/Modal";
import { useTracking } from "../../../../../hooks/use-tracking";
import { usePostcodeVerification } from "../../../../../hooks/use-postcode-verification";
import { ExistingMortgagedProperties } from "@sucasa-finance/origination-trpc";
import { TransitionInput } from "../../your-finances/your-household-expenses/TransitionInput";
import { useLocalModal } from "../../../../../hooks/use-local-modal";

const investmentPropertiesOptions = [
	{
		id: 1,
		label: "None",
		value: ExistingMortgagedProperties.None,
		name: "existingMortgagedProperties",
		checked: false
	},
	{
		id: 2,
		label: "One",
		value: ExistingMortgagedProperties.One,
		name: "existingMortgagedProperties",
		checked: false
	},
	{
		id: 3,
		label: "More than one",
		value: ExistingMortgagedProperties.MoreThanOne,
		name: "existingMortgagedProperties",
		checked: false
	},
];

const livingInPropertyOptions = [
	{
		id: 1,
		label: "Yes",
		value: "yes",
		name: "willLiveInExistingMortgagedProperty",
		checked: false
	},
	{
		id: 2,
		label: "No",
		value: "no",
		name: "willLiveInExistingMortgagedProperty",
		checked: false
	},
];

type RefinancingWorkflowFormType = {
	propertyPurpose?: string;
	propertyValuation?: string;
	propertyAddress?: Place;
	propertyAddressParts?: AddressParts;
	indicatedRentalIncomeForIP?: string;
	existingMortgagedProperties?: ExistingMortgagedProperties;
	willLiveInExistingMortgagedProperty?: string;
};
interface RefinancingWorkflowProps {
	submitted: boolean;
	onFinish: (isValid: boolean, propertyValuation: number | undefined) => void;
}
const RefinancingWorkflow: FC<RefinancingWorkflowProps> = ({
	onFinish,
	submitted,
}) => {
	const formState = useAppSelector((state) => state.form);
	const dispatch = useAppDispatch();
	const [currentPostcode, setCurrentPostcode] = useState<string | null>(null);
	const { trackEvent } = useTracking();
	const { modalOpen, modalClose, modalShow } = useLocalModal<"invalidPostcode" | "multipleInvestment">();

	const {
		isPostcodeValid,
		isError,
	} = usePostcodeVerification({
		postcode: currentPostcode,
		isInvestmentProperty: formState.propertyPurpose === "Investment"
	});

	// Effect to show the modal if the postcode is invalid
	useEffect(() => {
		if (isPostcodeValid) {
			trackEvent("Postcode Validation", {
				valid: "true",
				postcode: currentPostcode,
			});
			modalClose("invalidPostcode");
		}
		// Only show the modal when the postcode changes and is invalid
		if (isError && currentPostcode) {
			modalOpen("invalidPostcode");
			trackEvent("Postcode Validation", {
				valid: "false",
				postcode: currentPostcode,
			});
			trackEvent("Not In Remit Modal Shown", {
				reason: "Postcode Invalid",
				postcode: currentPostcode,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPostcode]); // Only trigger on postcode changes

	const defaultValues: Partial<RefinancingWorkflowFormType> = {
		propertyPurpose: formState.propertyPurpose,
		propertyValuation: formState.propertyValuation?.toString(),
		propertyAddress: undefined,
		indicatedRentalIncomeForIP: formState.indicatedRentalIncomeForIP?.toString(),
		existingMortgagedProperties: formState.existingMortgagedProperties,
		willLiveInExistingMortgagedProperty: formState.willLiveInExistingMortgagedProperty,
	};
	const {
		control,
		register,
		formState: { errors },
		setError,
		setValue,
		clearErrors,
		watch,
	} = useForm({
		defaultValues,
	});
	const [existingMortgagedProperties, willLiveInExistingMortgagedProperty] = watch(["existingMortgagedProperties", "willLiveInExistingMortgagedProperty"]);

	register("propertyPurpose", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			trackEvent("Property Purpose Selected", {
				selectedValue: value,
			});
			dispatch(setPropertyPurpose(value));
		},
	});

	useEffect(() => {
		if (
			(formState?.propertyAddress || formState?.propertyAddressParts) &&
			formState?.propertyPurpose &&
			formState?.propertyValuation
		) {
			onFinish(true, formState.propertyValuation);
		} else {
			onFinish(false, formState.propertyValuation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formState?.propertyAddress,
		formState?.propertyPurpose,
		formState?.propertyValuation,
		submitted,
	]);

	const maximumLendAmount = formState.propertyValuation
		? (formState.propertyValuation * 0.98).toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		: undefined;

	const minimumLendAmount = formState.propertyValuation
		? (formState.propertyValuation * 0.85).toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		: undefined;

	const borrowAmountHelperMessage = `Sucasa lends between 85-98% LVR. For this property valuation, that is between $${minimumLendAmount} - $${maximumLendAmount}.`;

	const valuationError =
		submitted &&
		(!formState?.propertyValuation ||
			Number.isNaN(formState?.propertyValuation) ||
			formState?.propertyValuation < 300_000)
			? "Please enter a valuation amount of at least $300,000 for the property you are refinancing."
			: undefined;

	const showRentalIncomeInput = formState.propertyPurpose === "Investment";
	const showLivingInPropertyQuestion = showRentalIncomeInput && formState.existingMortgagedProperties === ExistingMortgagedProperties.One;

	// Register the fields with onChange handlers
	register("existingMortgagedProperties", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value as ExistingMortgagedProperties;
			dispatch(setExistingMortgagedProperties(value));
		},
	});

	register("willLiveInExistingMortgagedProperty", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			dispatch(setWillLiveInExistingMortgagedProperty(value));
		},
	});

	// Effect to handle investment properties changes
	useEffect(() => {
		if (existingMortgagedProperties === ExistingMortgagedProperties.MoreThanOne
			|| (existingMortgagedProperties === ExistingMortgagedProperties.One
				&& willLiveInExistingMortgagedProperty === "no")) {
			modalOpen("multipleInvestment");
		}
	}, [existingMortgagedProperties, modalOpen, willLiveInExistingMortgagedProperty]);

	return (
		<>
			<RadioGroup
				selectedValue={formState?.propertyPurpose}
				radioOptions={liveInOptions}
				register={register}
				legend="What is your property used for?"
				error={
					errors.propertyPurpose &&
					"Please select the purpose of your property."
				}
			/>

			<TransitionInput show={Boolean(formState?.propertyPurpose && showRentalIncomeInput)}>
				<div>
					<div className="transition-all duration-300 ease-in-out">
						<DollarInput
							name="indicatedRentalIncomeForIP"
							control={control}
							onValueChange={(value) => {
								if (value) {
									setValue("indicatedRentalIncomeForIP", value.toString());
									dispatch(setIndicatedRentalIncomeForIP(value));
								} else {
									setValue("indicatedRentalIncomeForIP", "");
									dispatch(setIndicatedRentalIncomeForIP(0));
								}
							}}
							type="numeric"
							iconPrefix={<i className="icon-dollar" />}
							label="What is, or will be, the estimated or existing tenancy rental income?"
							placeholder="Amount per week"
							value={formState.indicatedRentalIncomeForIP}
						/>
					</div>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(
				formState?.propertyPurpose &&
				(!showRentalIncomeInput || (showRentalIncomeInput && formState.indicatedRentalIncomeForIP !== undefined))
			)}>
				<div>
					<AddressSearch
						helperEnabled={true}
						id="refinancePropertyAddress"
						options={{
							types: ["address"],
							componentRestrictions: { country: "au" },
						}}
						register={register}
						name="propertyAddress"
						onChange={({ place, addressParts, isManual }) => {
							clearErrors("propertyAddress");
							const hasRequiredParts =
								addressParts?.streetNumber &&
								addressParts?.streetName &&
								addressParts?.suburb &&
								addressParts?.state;
							if (!isManual && !hasRequiredParts) {
								setError("propertyAddress", {
									type: "manual",
									message:
										"Please ensure you select a valid address that includes a street number.",
								});
								return;
							}
							if (addressParts?.postalCode) {
								setCurrentPostcode(addressParts.postalCode);
							} else {
								setCurrentPostcode(null);
							}
							place && dispatch(setPropertyAddress(place));
							addressParts && dispatch(setPropertyAddressParts(addressParts));
						}}
						label={"What's the address?"}
						value={formState.propertyAddress}
						isSuburb={false}
						manualAddressParts={
							formState.propertyAddressParts || ({} as AddressParts)
						}
						error={errors.propertyAddress?.message}
					/>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(formState?.propertyAddress || formState.propertyAddressParts)}>
				<div>
					<DollarInput
						name="propertyValuation"
						control={control}
						onValueChange={(value) => {
							if (value) {
								setValue("propertyValuation", value?.toString());
								dispatch(setPropertyValuation(value));
							} else {
								setValue("propertyValuation", "");
								dispatch(setPropertyValuation(0));
							}
						}}
						type="numeric"
						iconPrefix={<i className="icon-dollar" />}
						label="What's the property valuation?"
						placeholder="Amount"
						value={formState?.propertyValuation}
						error={valuationError}
						helperMessage={
							valuationError
								? undefined
								: maximumLendAmount && borrowAmountHelperMessage
						}
					/>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(
				formState?.propertyValuation &&
				formState.propertyPurpose === "Investment" &&
				formState.indicatedRentalIncomeForIP !== undefined
			)}>
				<div>
					<div className="transition-all duration-300 ease-in-out">
						<RadioGroup
							selectedValue={formState.existingMortgagedProperties}
							radioOptions={investmentPropertiesOptions}
							legend="How many existing investment properties do you have with a mortgage?"
							register={register}
							name="existingMortgagedProperties"
						/>
					</div>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(showLivingInPropertyQuestion)}>
				<div>
					<div className="transition-all duration-300 ease-in-out">
						<RadioGroup
							selectedValue={formState.willLiveInExistingMortgagedProperty}
							radioOptions={livingInPropertyOptions}
							legend="Are you planning to live in your existing investment property?"
							register={register}
							name="willLiveInExistingMortgagedProperty"
						/>
					</div>
				</div>
			</TransitionInput>

			<Modal
				actions={[]}
				isOpen={modalShow === "invalidPostcode"}
				onClose={() => {
					modalClose("invalidPostcode");
					trackEvent("Postcode Validation", {
						valid: "true",
						postcode: currentPostcode,
					});
				}}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						Sucasa doesn't currently support loans for this postcode.
					</div>
				}
				content={
					<p className="text-center">
						If you submit your application, we'll be in touch when this changes.
					</p>
				}
			/>

			<Modal
				actions={[]}
				isOpen={modalShow === "multipleInvestment"}
				onClose={() => {
					modalClose("multipleInvestment");
				}}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						Multiple Investment Properties
					</div>
				}
				content={
					<p className="text-center">
						Sucasa is able to support investment loans as long as it's your only
						mortgaged investment property. We don't currently support investment
						loans where there is already an existing mortgaged investment
						property. If you submit your application, we'll be in touch when
						this changes. Otherwise, please select another option.
					</p>
				}
			/>
		</>
	);
};

export default RefinancingWorkflow;
