import { useCallback, useEffect } from "react";
import { useAppSelector } from "../redux-hooks";
import { selectAccessToken } from "../../store/slices/auth-slice";
import { FileStorageClient, FileStorageError } from "../../services/file-storage";
import type { CreateFilesRequest } from "../../services/file-storage.types";
import { useResetAuth } from "../use-reset-auth";
import { captureException } from "../../services/sentry.ts";

const fileStorageClient = new FileStorageClient();

export const useFileStorage = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const resetAuth = useResetAuth();

  useEffect(() => {
    fileStorageClient.setAccessToken(accessToken);
  }, [accessToken]);

  const createFiles = useCallback(async (payload: CreateFilesRequest[]) => {
    try {
      return await fileStorageClient.createFiles(payload);
    } catch (error: unknown) {
      if (error instanceof FileStorageError && error.status === 401) {
        resetAuth();
      }
      captureException(error as Error);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Error sending request to FileStorage: ${error instanceof Error ? error.message : error}`);
    }
  }, [resetAuth])

  const upload = useCallback(async (url: string, file: File) => {
    try {
      return await fileStorageClient.upload(url, file);
    } catch (error: unknown) {
      if (error instanceof FileStorageError && error.status === 401) {
        resetAuth();
      }
      captureException(error as Error);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Error sending request to FileStorage: ${error instanceof Error ? error.message : error}`);
    }
  }, [resetAuth])

  const download = useCallback(async (fileId: number) => {
    try {
      const data =  await fileStorageClient.getFile(String(fileId));
      if (!data.downloadUrl) {
        throw new Error(`No download URL: ${fileId}`);
      }
      window.open(data.downloadUrl, "_blank");
    } catch (error: unknown) {
      if (error instanceof FileStorageError && error.status === 401) {
        resetAuth();
      }
      captureException(error as Error);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Error sending request to FileStorage: ${error instanceof Error ? error.message : error}`);
    }
  }, [resetAuth])

  return {
    createFiles,
    upload,
    download,
  }
};
