import  { ApplicationStep, ApplicationSubStep, type CreateApplicationUnion, LoanPurpose } from "@sucasa-finance/origination-trpc";
import type { CreateOriginationApplicationResponse, GetApplicationByIdResponse} from "../types";
import {
    Step,
    SubStep,
    type Application,
    type CreateApplicationV2Request,
    type CreateApplicationResponse,
    LoanOnPropertyType,
    type HomeLoanSpecialist,
} from "../../create-application.schema";
import {
    maritalStatusMapping,
    stageMapping,
    featuresMapping,
    loanPurposeMapping,
    applicationTypeMapping,
    propertyPurposeMapping,
    creditCheckPreferenceMapping,
    loanProductPreferenceMapping,
    loanOnPropertyTypeMapping,
    applicationBorrowerTypeMapping,
    propertyTypeMapping,
} from "./enum-mapping";

import { formatDate } from './date';
import { mapReverseStepAndSubStep, mapStepAndSubStep } from "./step";

export function mapCreateApplicationRequest(from: CreateApplicationV2Request) {
    const {
        creditCheckPreference,
        purpose,
        stage,
        propertyType,
        addressParts,
        reason,
        firstHomeBuyer,
        purchasePrice,
        borrowAmount,
        savings,
        giftedAmount,
        giftedAmountGiftedBy,
        loanPreference,
        loanTermInYears,
        meetsNeedsAndObjectives,
        requestedLoanFeatures,
        applicationType,
        primaryApplicationDetails,
        // number of dependants is linked to the length of the array of dependantAges
        // numberOfDependants,
        dependantAges,
        residencyStatus,
        visaSubclassNumber,
        expectPRInThreeMonths,
        hasInvalidVisa,
        email,
        mobile,
        marketingConsent,
        privacyConsent,
        propertyValuation,
        refinanceLoanCurrentLender,
        refinanceLoanCurrentRemainingTermInMonths,
        refinanceLoanCurrentBorrowers,
        loansOnProperty,
        refinanceLoanAdditionalBorrowingAmount,
        refinanceLoanReasonForAdditionalFunds,
        refinanceLoanNewLoanHasSameBorrowersAsExistingLoan,
        reasonsForRefinancing,
        otherReasonForRefinancing,
        otherRequestLoanFeature,
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        utmTerm,
        utmId,
        fbclid,
        gclid,
        verificationToken,
        token,
        nonApplicantFinancialDetails,
        existingMortgagedProperties,
        willLiveInExistingMortgagedProperty,
        indicatedRentalIncomeForIP,
    } = from;

    const resolvedPurpose = loanPurposeMapping[purpose];
    if (!resolvedPurpose) {
        throw new Error(`Unknown loan purpose ${purpose}`);
    }

    let calculatedBorrowAmount = borrowAmount ?? 0;

    if (resolvedPurpose === LoanPurpose.Refinance) {
        const totalLoansOnProperty = loansOnProperty?.reduce((sum, loan) => sum + (loan.amount || 0), 0) || 0;
        const additionalBorrowingAmount = refinanceLoanAdditionalBorrowingAmount || 0;
        calculatedBorrowAmount = totalLoansOnProperty + additionalBorrowingAmount;
    }
    const stepMap = mapStepAndSubStep(from);
    const applicationDetails: CreateApplicationUnion = {
        purpose: resolvedPurpose,
        propertyPurpose: propertyPurposeMapping[reason],
        stageOfPurchase: stageMapping[stage],
        propertyType: propertyTypeMapping?.[propertyType],
        purchasePrice: purchasePrice ?? 0,
        borrowAmount: calculatedBorrowAmount,
        savingsAmount: savings ?? 0,
        giftedAmount: giftedAmount ?? 0,
        giftedAmountGiftedBy: giftedAmountGiftedBy ?? "",
        loanProductPreference: loanProductPreferenceMapping[loanPreference],
        loanTermInYears: loanTermInYears ? Number(loanTermInYears) : 0,
        loanMeetsNeedsAndObjectives: meetsNeedsAndObjectives,
        requestedLoanFeatures: requestedLoanFeatures.map(feature => featuresMapping[feature]),
        borrowerType: applicationTypeMapping[applicationType],
        dependents: dependantAges,
        nonApplicantFinancialDetails,
        propertyValuation,
        refinanceLoanCurrentLender,
        refinanceLoanCurrentRemainingTermInMonths,
        refinanceLoanCurrentBorrowers,
        refinanceLoanAdditionalBorrowingAmount,
        refinanceLoanReasonForAdditionalFunds,
        refinanceLoanNewLoanHasSameBorrowersAsExistingLoan,
        reasonsForRefinancing,
        reasonForRefinancingOther: otherReasonForRefinancing,
        requestedLoanFeaturesOther: otherRequestLoanFeature,
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        utmTerm,
        utmId,
        fbclid,
        gclid,
        existingMortgagedProperties,
        willLiveInExistingMortgagedProperty,
        indicatedRentalIncomeForIP,
        property: {
            unit: addressParts.unitNumber ?? "",
            number: addressParts.streetNumber ?? "",
            street: addressParts.streetName || "",
            state: addressParts.state ?? "",
            postCode: Number(addressParts.postalCode ?? 0),
            city: addressParts.suburb ?? "",
            country: addressParts.country ?? "Australia"
        },
        user: {
            residencyStatus,
            visaSubclassNumber,
            email,
            marketingConsent: !marketingConsent,
            privacyConsent,
            phone: mobile,
            title: primaryApplicationDetails.title,
            firstName: primaryApplicationDetails.firstName,
            lastName: primaryApplicationDetails.surname,
            middleName: primaryApplicationDetails.middleName,
            maritalStatus: maritalStatusMapping[primaryApplicationDetails.maritalStatus],
            creditCheckPreference: creditCheckPreferenceMapping[creditCheckPreference],
            firstHomeBuyer,
        },
        applicantUser: {
            marriedToApplicantOnApplication: primaryApplicationDetails.marriedToApplicantOnApplication,
            expectPRInThreeMonths,
            hasInvalidVisa,
        },
        liabilities: (loansOnProperty ?? []).map((loan, index, array) => {
            const name = `Property Loan ${array.length > 1 ? index + 1 : ""}`.trim();
            // If loan type is not provided, assume it's a variable loan
            const loanType = loan.type ?? LoanOnPropertyType.Variable;

            const type = loanOnPropertyTypeMapping[loanType as LoanOnPropertyType]
            if (!type) {
                throw new Error(`Expected mapped type on loan: ${index}`);
            }
            return {
                name,
                type,
                value: loan.amount ?? 0,
                interestRate: loan.interestRate,
                monthlyExpense: loan.monthlyRepayment,
                fixedRateExpiryDate: formatDate(loan.fixedRateExpiryDate),
            }
        }),
        applicationStep: stepMap?.step || ApplicationStep.Details,
        applicationSubStep: stepMap?.subStep || ApplicationSubStep.ApplicantBirthdate
    };
    return {
        otpCode: token,
        verificationToken: verificationToken,
        applicationDetails,
    }
}

export function mapCreateApplicationResponse({ application, access_token: authToken }: CreateOriginationApplicationResponse, request: CreateApplicationV2Request): CreateApplicationResponse & { authToken?: string } {
    const [applicant] = application.applicants;
    if (!applicant) {
        throw new Error("Expected applicant to be returned with application");
    }
    return {
        data: {
            content: {
                notInRemit: !application.inRemit,
                // TODO[matej]: get hls from origination
                homeLoanSpecialist: {
                    email: "TODO to hard code, get details",
                    name: "TODO",
                    phone: "TODO",
                    meetingLink: "TODO"
                },
                applicationDetails: {
                    ...request,
                    ...application,
                    applicationStep: request.applicationStep || "",
                    applicationSubStep: request.applicationSubStep || "",
                    // do not populate app id
                    appId: "",// application.id.toString(),
                    applicationId: String(application.id),
                    createdDate: application.createdAt.toString(),
                    jointApplicantHasBeenInvited: false,
                    purposeOfApplication: request.purpose,
                    loanDetails: {
                        propertyAddress: "TODO property trying to purchase. Refinancing -> Full address, ",
                        purchasePrice: (application.purchasePrice || "").toString(),
                        loanAmount: "-999",
                        loanTermInYears: application.loanTermInYears
                    },
                    applicationVersionCanUseNewPortal: true
                },
                userDetails: {
                    firstName: request.primaryApplicationDetails.firstName,
                    middleName: request.primaryApplicationDetails.middleName,
                    lastName: request.primaryApplicationDetails.surname,
                    name: request.primaryApplicationDetails.firstName,
                    fullName: [
                        request.primaryApplicationDetails.firstName,
                        request.primaryApplicationDetails.middleName,
                        request.primaryApplicationDetails.surname
                    ].filter(Boolean).join(" "),
                    email: request.email,
                    streetAddress: "TODO",
                    userAddress: "TODO",
                    userMobile: request.mobile,
                    userId: applicant.userId.toString(),
                    applicantType: 'Primary Applicant',
                    applications: [application.id],
                }
            }
        },
        authToken
    }
}

type GetApplicationMappingResponse = {
    homeLoanSpecialist: HomeLoanSpecialist;
    application: Application;
    notInRemit: boolean;
    isPrimaryApplicant: boolean;
    jointBorrowerFirstName?: string;
    otherApplicantsHaveInvalidVisa: boolean;
};
export const mapGetApplicationByIdResponse = (application: GetApplicationByIdResponse): GetApplicationMappingResponse => {
    const currentApplicant = application.applicants.find(itm => itm.userId === application.currentUserId);
    if (!currentApplicant) {
        throw new Error("Expected applicant to be returned with application");
    }
    const jointBorrowerFirstName = application.applicants
      .find(itm => itm.userId !== currentApplicant.userId)?.firstName;

    const stepMap = mapReverseStepAndSubStep({
        step: application.applicationStep,
        subStep: application.applicationSubStep,
    });

    // check if other applicants have an invalid visa
    const otherApplicantsHaveInvalidVisa = application?.applicants
      ?.some(itm => itm.userId !== application.currentUserId && itm.hasInvalidVisa === true);

    return {
        notInRemit: !application.inRemit,
        isPrimaryApplicant: !application.isJointApplicant,
        homeLoanSpecialist: application.hls,
        jointBorrowerFirstName,
        otherApplicantsHaveInvalidVisa,
        application: {
            createdDate: application.createdAt.toString(),
            // do not populate app id
            appId: "",
            applicationId: String(application.id),
            applicationStep: stepMap?.applicationStep ?? Step.YourDetails,
            applicationSubStep: stepMap?.applicationSubStep ?? SubStep.FineDetailsAboutYou,
            applicationType: applicationBorrowerTypeMapping[application.borrowerType],
            jointApplicantHasBeenInvited: application.isJointApplicantInvited,
            purposeOfApplication: application.purpose,
            applicationVersionCanUseNewPortal: true,
            loanDetails: {
                propertyAddress: application.property ?? "",
                purchasePrice: (application.purchasePrice ?? "").toString(),
                loanAmount: application.borrowAmount.toString(),
                loanTermInYears: application.loanTermInYears,
            },
            dependents: application.dependents,
            status: application.status,
            workingTowardsStatus: application.workingTowardsStatus
        }
    };
}
