import { type FC, useCallback} from "react";
import { formatTime } from "../../../../../helpers/format-time";
import { useFileStorage } from "../../../../../hooks/origination/use-file-storage";

type ChatMessageFileProps = {
	name: string;
	size?: string;
	time?: string;
	fileId?: number;
}
export const ChatMessageFile: FC<ChatMessageFileProps> = ({
	name,
	size,
	time,
	fileId,
}) => {
	const { download } = useFileStorage();
	const getFile = useCallback(() => {
		if (fileId) {
			void download(fileId);
		}
	}, [download, fileId]);

	return (<div className="flex flex-row justify-end items-end w-full gap-4">
		<div className="p-4 bg-lavender-200 rounded">
			<button
				type="button"
				onClick={getFile}
				className="flex flex-row items-center">
				<div className="flex items-center gap-2 text-base font-normal">
					<i className="icon-document text-2xl" />
					<span>{name}</span>
				</div>
			</button>

			{size && <span className="text-xs text-off-black-600">{size}</span>}
			{time && <div className="flex flex-row justify-end pt-1 text-xs text-off-black-600">
				<span>{formatTime(time)}</span>
			</div>}
		</div>
	</div>)
};
