import { useCallback, useState } from "react";

type OkModal = "default" ;

export const useLocalModal  = <T = OkModal>() => {
	const [showModalList, setShowModalList] = useState<T[]>([]);

	const modalOpen = useCallback((modal: T) => {
		setShowModalList(oldList => oldList.includes(modal) ? oldList : [...oldList, modal]);
	}, []);

	const modalClose = useCallback((modal: T) => {
		setShowModalList(oldList => oldList.filter((item) => item !== modal));
	}, []);

	return {
		modalOpen,
		modalClose,
		modalShow: showModalList?.[0]
	}
};
