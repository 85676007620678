import { type FC, type ReactNode, useCallback } from "react";
import { formatTime } from "../../../../../helpers/format-time";
import { BiometricsInstructions } from "./BiometricsInstructions";
import { useFileStorage } from "../../../../../hooks/origination/use-file-storage";

type ChatMessageAdminProps = {
	message: ReactNode | string;
	sender: string;
	name?: string;
	fileId?: number;
	time?: string;
	notSeen?: boolean;
	isBiometrics?: boolean;
}
export const ChatMessageAdmin: FC<ChatMessageAdminProps> = ({
	message,
	sender,
	name,
	fileId,
	time,
	notSeen = false,
	isBiometrics
}) => {
	const { download } = useFileStorage();
	const getFile = useCallback(() => {
		if (fileId) {
			void download(fileId);
		}
	}, [download, fileId]);

	return (<div className="flex flex-row justify-start items-end w-full gap-4">
		<div className="relative block bg-white rounded-full p-2">
			<i className="icon-sucasa-logo text-2xl" />
			{notSeen && <div
				className="h-2 w-2 absolute top-0 right-0 rounded-full bg-red-brick-100 text-white text-xs"></div>}
		</div>
		<div className="p-4 bg-white rounded w-full">
			<div>{message}</div>
			{isBiometrics && <div>
				<div className="py-4">Here is what you need to do next:</div>
				<BiometricsInstructions />
			</div>}

			{name && fileId && <button
				type="button"
				onClick={getFile}
				className="flex flex-row items-center"
			>
				<div className="flex items-center gap-2 text-base font-normal">
					<i className="icon-document text-2xl" />
					<span>{name}</span>
				</div>
			</button>}

			<div className="flex flex-row justify-between pt-2 text-xs text-off-black-600">
				<span>{sender}</span>
				{time && <span>{formatTime(time)}</span>}
			</div>
		</div>
	</div>);
}
