import type { FC } from "react";
import Modal from "../../../modal/Modal";

type VisaErrorModalProps = {
	isOpen: boolean;
	onClose: () => void;
	isJoint?: boolean;
}
export const VisaErrorModal: FC<VisaErrorModalProps> = ({isOpen, onClose, isJoint}) => {
	return (<Modal
		isOpen={isOpen}
		onClose={onClose}
		title={
			<div className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
				<i className="icon-warning text-3xl md:text-[80px]" />
			</div>
		}
		content={<>
			<p className="text-sm text-primary text-center">
				Our lending criteria requires at least 1 borrower to be either an Australian Permanent Resident,
				Australian Citizen or New Zealand Citizen.{' '}
				{`Unfortunately, your responses indicated that ${isJoint ? "none of the borrowers on your application" : "you don't"} fit this criteria.`}
			</p>
			<p className="text-sm text-primary text-center pt-5">
				We're sorry to not be of more assistance and are working hard to expand our eligibility criteria.
			</p>
		</>}
		actions={[
			{
				text: "Close",
				variant: "primary",
				handleClick: onClose,
				type: "button",
				textAlign: "center",
				size: "fit",
			},
		]}
	/>);
}
