import { type FC, useCallback, useState } from "react";
import{ useDropzone } from "react-dropzone";
import Button from "../../../../ui/button/Button";
import { FileUploadFile } from "./FileUploadFile";
import { useFileStorage } from "../../../../../hooks/origination/use-file-storage";
import { captureException } from "../../../../../services/sentry";
import { formatFileTypeAndSize } from "../../../../../helpers/format-file-type-and-size";

export type UploadCallbackParameters = {
	fileId: number;
	name: string;
	error?: string;
};

type FileUploadProps = {
	applicationId: number;
	onUpload?: (files?: UploadCallbackParameters[]) => void
}
export const FileUpload: FC<FileUploadProps> = ({applicationId, onUpload}) => {
	const [files, setFiles] = useState<File[]>([]);
	const [isUploading, setIsUploading] = useState(false);
	const { createFiles, upload } = useFileStorage();

	const onDrop = useCallback((acceptedFiles: File[]) => {
		setFiles([
			...files,
			...acceptedFiles,
		]);
	}, [files]);

	const onSubmitCallback = useCallback(async () => {
		setIsUploading(true);
		try {
			const createdFiles = await createFiles(files.map(itm => ({
				name: itm.name,
				contentType: itm.type,
				prefix: String(applicationId)
			})));
			const uploadResponse: UploadCallbackParameters[] = await Promise.all(createdFiles.map(async (itm, index) => {
				try {
					if (!files[index] || files[index] === undefined) {
						throw new Error("Uploaded file is missing");
					}
					const response = await upload(itm.uploadUrl, files[index]);
					if (response !== 'OK') {
						throw new Error("Failed to upload file");
					}
					return {
						fileId: itm.fileId,
						name: itm.name,
					}
				} catch (error) {
					captureException(error as Error);
					return {
						fileId: itm.fileId,
						name: itm.name,
						error: (error as Error).message
					}
				}
			}));
			onUpload?.(uploadResponse);
		} catch (error: unknown) {
			captureException(error as Error);
		} finally {
			setIsUploading(false);
		}
	}, [applicationId, createFiles, files, onUpload, upload]);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
	});

	const removeFile = useCallback((indexToRemove: number) => {
		setFiles(previousFiles => previousFiles.filter((_, index) => index !== indexToRemove));
	}, []);

	return (<div className="bg-white">
		{files.length <= 0 && <div className="relative">
			<Button
				className="w-4 h-4 ms-0 text-off-black-900 absolute top-4 right-4"
				text={<i className="icon-remove" />}
				variant="link"
				textAlign="center"
				handleClick={() => onUpload?.()}
			/>
			<div {...getRootProps({ className: "flex flex-col items-center p-4 rounded border border-dashed border-off-black-900" })}>
				<input {...getInputProps()} />
				<i className="icon-plus text-2xl" />
				{!isDragActive && <span className="text-lg">Drag & drop or <span className="underline cursor-pointer">browse</span></span>}
				{isDragAccept && (<span className="text-xs">All files will be accepted</span>)}
				{isDragReject && (<span className="text-xs">Some files will be rejected</span>)}
				<span className="text-xs">Upload PDF files only</span>
			</div>
		</div>}
		{files.length > 0 && <div className="p-4 rounded bg-opacity-10 bg-off-black-600 max-w-full">
			<div className="max-w-full overflow-y-auto">
				<div className="flex flex-row gap-2 pb-4">
					{files.map((file, index) => (
						<div key={index} className="relative">
							<FileUploadFile
								name={file.name}
								size={formatFileTypeAndSize(file.type, file.size)}
								file={file}
							/>
							<Button
								className="w-6 h-6 absolute top-1 right-1 bg-opacity-50 rounded-full flex items-center justify-center hover:bg-opacity-75 transition-all"
								text={<i className="icon-remove text-sm text-white" />}
								variant="link"
								textAlign="center"
								handleClick={() => { removeFile(index); }}
								/>
						</div>
					))}
					<div {...getRootProps({ className: "flex flex-col items-center justify-center p-4 rounded border border-dashed border-off-black-900 h-44 w-28" })}>
						<input {...getInputProps()} />
						<i className="icon-plus text-2xl" />
					</div>
				</div>
			</div>
			<div
				className="flex flex-row justify-between items-center p-2 bg-white rounded">
				<Button
					className="w-10 h-10 ms-0 text-off-black-900"
					text={<i className="icon-revert text-2xl" />}
					variant="link"
					textAlign="center"
					isDisabled={isUploading}
					handleClick={() => {
						setFiles([]);
					}}
				/>
				{files.length > 0 && <span className="text-base">{files.length} files attached</span>}
				<Button
					className="min-w-min rounded"
					text="Submit"
					variant="primary"
					textAlign="center"
					isDisabled={isUploading}
					handleClick={onSubmitCallback}
				/>
			</div>
		</div>}
	</div>);
};
