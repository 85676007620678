import { type FormEvent, useEffect, useMemo, useState } from "react";
import {
	firstBuyerOptions,
	liveInOptions,
	selectOptions,
	vacantLandOptions,
} from "../../../../utils/BootList";
import SelectInput from "../../../select-input/SelectInput";
import { useForm } from "react-hook-form";
import type { OptionsType } from "../../../../../common/types";
import {
	useAppSelector,
	useAppDispatch,
} from "../../../../../hooks/redux-hooks";
import RadioGroup from "../../../radio-group/RadioGroup";
import {
	clearPropertyAddress,
	clearPropertyAddressParts,
	setBorrowerStage,
	setFirstHomeBuyer,
	setIsVacantLandOrHomeAndLand,
	setPropertyAddress,
	setPropertyAddressParts,
	setPropertyPurpose,
	setIndicatedRentalIncomeForIP,
	setExistingMortgagedProperties,
	setWillLiveInExistingMortgagedProperty,
} from "../../../../../store/slices/form-slice";
import AddressSearch, {
	type Place,
} from "../../../address-search/AddressSearch";
import type { AddressParts } from "../../../../../services/apis/create-application.schema";
import Modal from "../../../modal/Modal";
import { useTracking } from "../../../../../hooks/use-tracking";
import { usePostcodeVerification } from "../../../../../hooks/use-postcode-verification";
import DollarInput from "../../../dollar-input/DollarInput";
import { ExistingMortgagedProperties } from "@sucasa-finance/origination-trpc";
import { TransitionInput } from "../../your-finances/your-household-expenses/TransitionInput";
import { useLocalModal } from "../../../../../hooks/use-local-modal";

const investmentPropertiesOptions = [
	{
		id: 1,
		label: "None",
		value: ExistingMortgagedProperties.None,
		name: "existingMortgagedProperties",
		checked: false,
	},
	{
		id: 2,
		label: "One",
		value: ExistingMortgagedProperties.One,
		name: "existingMortgagedProperties",
		checked: false,
	},
	{
		id: 3,
		label: "More than one",
		value: ExistingMortgagedProperties.MoreThanOne,
		name: "existingMortgagedProperties",
		checked: false,
	},
];

const livingInPropertyOptions = [
	{
		id: 1,
		label: "Yes",
		value: "yes",
		name: "willLiveInExistingMortgagedProperty",
		checked: false,
	},
	{
		id: 2,
		label: "No",
		value: "no",
		name: "willLiveInExistingMortgagedProperty",
		checked: false,
	},
];

type BuyingWorkflowFormType = {
	borrowerStage: OptionsType;
	propertyPurpose?: string;
	firstHomeBuyer?: string;
	isVacantLandOrHomeAndLand?: string;
	propertyAddress?: Place;
	propertyAddressParts?: AddressParts;
	indicatedRentalIncomeForIP?: string;
	existingMortgagedProperties?: ExistingMortgagedProperties;
	willLiveInExistingMortgagedProperty?: string;
};
interface BuyingWorkflowProps {
	onFinish: (value: boolean) => void;
}
const BuyingWorkflow = ({ onFinish }: BuyingWorkflowProps) => {
	const formState = useAppSelector((state) => state.form);
	const { propertyAddress } = formState;
	const dispatch = useAppDispatch();
	const [currentPostcode, setCurrentPostcode] = useState<string | null>(null);
	const { trackEvent } = useTracking();
	const { modalOpen, modalClose, modalShow } = useLocalModal<"invalidPostcode" | "vacantLand" | "multipleInvestment">();

	const { isPostcodeValid, isError, error } = usePostcodeVerification({
		postcode: currentPostcode,
		isInvestmentProperty: formState.propertyPurpose === "Investment",
	});

	// Effect to show the modal if the postcode is invalid
	useEffect(() => {
		if (isPostcodeValid) {
			trackEvent("Postcode Validation", {
				valid: "true",
				postcode: currentPostcode,
			});
			modalClose("invalidPostcode");
		}
		// Only show the modal when the postcode changes and is invalid
		if (isError && currentPostcode) {
			modalOpen("invalidPostcode");
			trackEvent("Postcode Validation", {
				valid: "false",
				postcode: currentPostcode,
			});
			trackEvent("Not In Remit Modal Shown", {
				reason: "Postcode Invalid",
				postcode: currentPostcode,
			});
		}
	}, [currentPostcode, isPostcodeValid, isError, trackEvent, modalOpen, modalClose]);

	const defaultValues: BuyingWorkflowFormType = {
		borrowerStage: formState.borrowerStage ?? {
			id: 5,
			label: "Select option",
			value: "select_option",
		},
		propertyPurpose: formState.propertyPurpose,
		firstHomeBuyer: formState.firstHomeBuyer,
		isVacantLandOrHomeAndLand: formState.isVacantLandOrHomeAndLand,
		indicatedRentalIncomeForIP:
			formState.indicatedRentalIncomeForIP?.toString(),
		existingMortgagedProperties: formState.existingMortgagedProperties,
		willLiveInExistingMortgagedProperty:
			formState.willLiveInExistingMortgagedProperty,
	};
	const {
		register,
		watch,
		setValue,
		setError,
		clearErrors,
		control,
		formState: { errors },
	} = useForm({
		defaultValues,
	});
	const [stage, existingMortgagedProperties, willLiveInExistingMortgagedProperty] = watch(["borrowerStage", "existingMortgagedProperties", "willLiveInExistingMortgagedProperty"]);

	const isSuburb = useMemo(() => [1, 2, 3].includes(stage?.id), [stage?.id]);
	const [options, setOptions] = useState({
		types: isSuburb ? ["(regions)"] : ["address"],
		componentRestrictions: { country: "au" },
	});

	useEffect(() => {
		setOptions({
			...options,
			types: isSuburb ? ["(regions)"] : ["address"],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stage]);

	register("propertyPurpose", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			trackEvent("Property Purpose Selected", {
				selectedValue: event.currentTarget.value,
			});
			dispatch(setPropertyPurpose(event.currentTarget.value));
		},
	});
	register("firstHomeBuyer", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			dispatch(setFirstHomeBuyer(event.currentTarget.value));
		},
	});
	register("isVacantLandOrHomeAndLand", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			dispatch(setIsVacantLandOrHomeAndLand(value));
			if (value === "yes") {
				modalOpen("vacantLand");
				trackEvent("Not In Remit Modal Shown", {
					reason: "Vacant Land or Home and Land Package",
				});
			}
			trackEvent("Vacant Land or Home and Land Selected", {
				selectedValue: value,
			});
		},
	});

	const showRentalIncomeInput = formState.propertyPurpose === "Investment";
	const showLivingInPropertyQuestion =
		showRentalIncomeInput &&
		formState.existingMortgagedProperties === ExistingMortgagedProperties.One;

	useEffect(() => {
		const hasRequiredBaseFields = Boolean(
			formState?.borrowerStage?.id !== 0 &&
				(formState?.propertyAddress ||
					(isSuburb && formState?.propertyAddressParts?.suburb) ||
					(!isSuburb && formState?.propertyAddressParts)) &&
				formState?.propertyPurpose &&
				formState?.firstHomeBuyer &&
				formState?.isVacantLandOrHomeAndLand
		);

		// If it's an investment property, we need the rental income and existing investment properties count
		const isInvestmentProperty = formState.propertyPurpose === "Investment";
		const hasRequiredInvestmentFields =
			!isInvestmentProperty ||
			(Boolean(
				formState.indicatedRentalIncomeForIP !== undefined &&
					formState.indicatedRentalIncomeForIP > 0
			) &&
				Boolean(formState.existingMortgagedProperties));

		onFinish(hasRequiredBaseFields && hasRequiredInvestmentFields);
	}, [
		formState?.borrowerStage?.id,
		formState?.firstHomeBuyer,
		formState?.isVacantLandOrHomeAndLand,
		formState?.propertyAddress,
		formState?.propertyAddressParts,
		formState?.propertyPurpose,
		formState?.indicatedRentalIncomeForIP,
		formState?.existingMortgagedProperties,
		isSuburb,
		onFinish,
	]);

	// Register the fields with onChange handlers
	register("existingMortgagedProperties", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value as ExistingMortgagedProperties;
			dispatch(setExistingMortgagedProperties(value));
		},
	});

	register("willLiveInExistingMortgagedProperty", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			dispatch(setWillLiveInExistingMortgagedProperty(value));
		},
	});

	// Effect to handle investment properties changes
	useEffect(() => {
		if (existingMortgagedProperties === ExistingMortgagedProperties.MoreThanOne
			|| (existingMortgagedProperties === ExistingMortgagedProperties.One
				&& willLiveInExistingMortgagedProperty === "no")) {
			modalOpen("multipleInvestment");
		}
	}, [existingMortgagedProperties, modalOpen, willLiveInExistingMortgagedProperty]);

	return (
		<>
			<RadioGroup
				selectedValue={formState.propertyPurpose}
				radioOptions={liveInOptions}
				legend="Why are you buying?"
				register={register}
				name="propertyPurpose"
			/>

			<TransitionInput show={Boolean(formState?.propertyPurpose && showRentalIncomeInput)}>
				<div>
					<div className="transition-all duration-300 ease-in-out">
						<DollarInput
							name="indicatedRentalIncomeForIP"
							control={control}
							onValueChange={(value) => {
								if (value) {
									setValue("indicatedRentalIncomeForIP", value.toString());
									dispatch(setIndicatedRentalIncomeForIP(value));
								} else {
									setValue("indicatedRentalIncomeForIP", "");
									dispatch(setIndicatedRentalIncomeForIP(0));
								}
							}}
							type="numeric"
							iconPrefix={<i className="icon-dollar" />}
							label="What is, or will be, the estimated or existing tenancy rental income?"
							placeholder="Amount per week"
							value={formState.indicatedRentalIncomeForIP}
						/>
					</div>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(
				formState?.propertyPurpose &&
				(!showRentalIncomeInput || (showRentalIncomeInput && formState.indicatedRentalIncomeForIP !== undefined))
			)}>
				<div>
					<SelectInput
						name="borrowerStage"
						value={
							stage || {
								id: 0,
								value: "",
								label: "Select an option",
							}
						}
						label="When are you buying?"
						options={selectOptions}
						register={register}
						onChangeHandler={(event: OptionsType) => {
							if (
								(stage?.id === 3 || stage?.id === 2 || stage?.id === 1) &&
								event.id === 4
							) {
								dispatch(clearPropertyAddress());
								dispatch(clearPropertyAddressParts());
								setValue("propertyAddress", undefined);
							} else if (
								stage?.id === 4 &&
								(event.id === 3 || event.id === 2 || event.id === 1)
							) {
								dispatch(clearPropertyAddress());
								dispatch(clearPropertyAddressParts());
								setValue("propertyAddress", undefined);
							}
							dispatch(setBorrowerStage(event));
						}}
					/>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(stage?.id !== 0)}>
				<div>
					<AddressSearch
						helperEnabled={true}
						id="propertyAddress"
						options={options}
						register={register}
						name="propertyAddress"
						onChange={({ place, addressParts, isManual }) => {
							clearErrors("propertyAddress");
							if (isSuburb) {
								const hasRequiredParts =
									addressParts?.postalCode &&
									addressParts?.suburb &&
									addressParts?.state;
								if (!isManual && !hasRequiredParts) {
									setError("propertyAddress", {
										type: "manual",
										message: "Please ensure you select a valid suburb.",
									});
									return;
								}
							} else {
								const hasRequiredParts =
									addressParts?.streetNumber &&
									addressParts?.streetName &&
									addressParts?.suburb &&
									addressParts?.state;
								if (!isManual && !hasRequiredParts) {
									setError("propertyAddress", {
										type: "manual",
										message:
											"Please ensure you select a valid address that includes a street number.",
									});
									return;
								}
							}
							if (addressParts?.postalCode) {
								setCurrentPostcode(addressParts.postalCode);
							} else {
								setCurrentPostcode(null);
							}
							place && dispatch(setPropertyAddress(place));
							addressParts && dispatch(setPropertyAddressParts(addressParts));
						}}
						label={
							isSuburb
								? "What's the suburb you are looking at?"
								: "Great! What's the address?"
						}
						value={propertyAddress}
						isSuburb={isSuburb}
						manualAddressParts={
							formState.propertyAddressParts || ({} as AddressParts)
						}
						error={errors.propertyAddress?.message}
					/>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(formState?.propertyAddress || formState.propertyAddressParts)}>
				<div>
					<RadioGroup
						selectedValue={formState.firstHomeBuyer}
						radioOptions={firstBuyerOptions}
						legend="Are you a first home buyer?"
						register={register}
						name="firstHomeBuyer"
					/>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(formState?.firstHomeBuyer)}>
				<div>
					<RadioGroup
						selectedValue={formState.isVacantLandOrHomeAndLand}
						radioOptions={vacantLandOptions}
						legend="Are you buying vacant land or a home-and-land package?"
						register={register}
						name="isVacantLandOrHomeAndLand"
					/>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(
				formState?.isVacantLandOrHomeAndLand &&
				formState.propertyPurpose === "Investment" &&
				formState.indicatedRentalIncomeForIP !== undefined
			)}>
				<div>
					<div className="transition-all duration-300 ease-in-out">
						<RadioGroup
							selectedValue={formState.existingMortgagedProperties}
							radioOptions={investmentPropertiesOptions}
							legend="How many existing investment properties do you have with a mortgage?"
							register={register}
							name="existingMortgagedProperties"
						/>
					</div>
				</div>
			</TransitionInput>

			<TransitionInput show={Boolean(showLivingInPropertyQuestion)}>
				<div>
					<div className="transition-all duration-300 ease-in-out">
						<RadioGroup
							selectedValue={formState.willLiveInExistingMortgagedProperty}
							radioOptions={livingInPropertyOptions}
							legend="Are you planning to live in your existing investment property?"
							register={register}
							name="willLiveInExistingMortgagedProperty"
						/>
					</div>
				</div>
			</TransitionInput>

			<Modal
				actions={[]}
				isOpen={modalShow === "invalidPostcode"}
				onClose={() => {
					modalClose("invalidPostcode");
					trackEvent("Postcode Validation", {
						valid: "true",
						postcode: currentPostcode,
					});
				}}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						{error &&
						"data" in error &&
						error.data &&
						typeof error.data === "object" &&
						"message" in error.data &&
						error.data.message ===
							"We currently don't support investment loans in this postcode"
							? "Investment Property Postcode Restriction"
							: "Postcode Not Supported"}
					</div>
				}
				content={
					<p className="text-center">
						{error &&
						"data" in error &&
						error.data &&
						typeof error.data === "object" &&
						"message" in error.data &&
						error.data.message ===
							"We currently don't support investment loans in this postcode"
							? "We currently don't support investment loans in this postcode. If you submit your application, we'll be in touch when this changes."
							: "Sucasa doesn't currently support loans for this postcode. If you submit your application, we'll be in touch when this changes."}
					</p>
				}
			/>

			<Modal
				isOpen={modalShow === "vacantLand"}
				actions={[]}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						Sucasa isn't currently able to support loans for vacant land or home
						and land packages.
					</div>
				}
				onClose={() => {
					modalClose("vacantLand");
				}}
				content={
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary text-center">
							We don't currently support loans for vacant land or home and land
							packages. If you submit your application, we'll be in touch when
							this changes.
						</p>
						<p className="text-sm text-primary text-center">
							Otherwise, please select another option.
						</p>
					</div>
				}
			/>

			<Modal
				actions={[]}
				isOpen={modalShow === "multipleInvestment"}
				onClose={() => {
					modalClose("multipleInvestment");
				}}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						Multiple Investment Properties
					</div>
				}
				content={
					<p className="text-center">
						Sucasa is able to support investment loans as long as it's your only
						mortgaged investment property. We don't currently support investment
						loans where there is already an existing mortgaged investment
						property. If you submit your application, we'll be in touch when
						this changes. Otherwise, please select another option.
					</p>
				}
			/>
		</>
	);
};

export default BuyingWorkflow;
