import { parse } from "date-fns";
import { captureException } from "../services/sentry";

export interface EmploymentHistory {
  jobStartDate: string;
  jobEndDate?: string;
}

export const calculateEmploymentGaps = (employmentHistories: EmploymentHistory[]): boolean => {
  if (!employmentHistories || employmentHistories.length <= 1) {
    return false;
  }

  // Sort employment histories by job start date descending
  const sortedHistories = [...employmentHistories]
    .filter(history => history?.jobStartDate) // Filter out entries without start dates
    .sort((a, b) => {
      try {
        const dateA = parse(b.jobStartDate, "dd/MM/yyyy", new Date());
        const dateB = parse(a.jobStartDate, "dd/MM/yyyy", new Date());
        return dateA.getTime() - dateB.getTime();
      } catch (error) {
        captureException(error as Error, {
          extra: {
            context: "Employment history date sorting",
            dateA: b.jobStartDate,
            dateB: a.jobStartDate
          }
        });
        console.error("Error parsing dates:", error);
        return 0;
      }
    });

  for (let index = 0; index < sortedHistories.length - 1; index++) {
    const current = sortedHistories[index];
    const next = sortedHistories[index + 1];

    if (!current?.jobStartDate || !next?.jobEndDate) {
      continue;
    }

    try {
      const currentStartDate = parse(current.jobStartDate, "dd/MM/yyyy", new Date());
      const nextEndDate = parse(next.jobEndDate, "dd/MM/yyyy", new Date());

      const gapInDays = (currentStartDate.getTime() - nextEndDate.getTime()) / (1000 * 60 * 60 * 24);

      if (gapInDays > 30) {
        return true;
      }
    } catch (error) {
      captureException(error as Error, {
        extra: {
          context: "Employment gap calculation",
          currentStartDate: current.jobStartDate,
          nextEndDate: next.jobEndDate,
          employmentHistoryIndex: index
        }
      });
      console.error("Error calculating gap:", error);
      continue;
    }
  }

  return false;
};
