import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import type { UpdateDetailsRequest } from "../../services/apis/update-details.schema";
import { SubStep, Step } from "../../services/apis/create-application.schema";
import { LivingSituation } from "@sucasa-finance/origination-trpc";

export const selectUpdateDetailsPayload = createSelector(
	[(state: RootState) => state.form],
	(form) => (isJoint: boolean) => {
		const livingSituation = form.currentLivingSituation?.value as LivingSituation;
		const payload: Partial<UpdateDetailsRequest> = {
			applicationStep: Step.YourFinances,
			applicationSubStep: SubStep.YourEmployment,
			addressHistory: form.addressHistory?.map((address, index) => ({
				currentAddress: index === 0,
				previousAddress: index > 0,
				streetNumber: address.livingAddressParts?.streetNumber,
				streetName: address.livingAddressParts?.streetName,
				suburb: address.livingAddressParts?.suburb ?? "",
				state: address.livingAddressParts?.state ?? "",
				postalCode: address.livingAddressParts?.postalCode ?? "",
				...(address.livingAddressParts?.unitNumber && {
					unitNumber: address.livingAddressParts?.unitNumber,
				}),
			})),
			birthdate: form.birthDate,
			exitStrategy: form.exitStrategy?.toString() + (form.exitStrategyOther ? ',' + form.exitStrategyOther : ''),
			exitStrategyDescription: form.exitStrategyDetails,
			livingSituation,
			postSettlementPlan: livingSituation === LivingSituation.OwnWithMortgage ? form.postSettlementPlan : undefined,
			postSettlementEstimate: livingSituation === LivingSituation.OwnWithMortgage ? form.postSettlementEstimate : undefined,
			postSettlementMonthlyCosts: livingSituation === LivingSituation.OwnWithMortgage ? form.postSettlementMonthlyCosts : undefined,
			privacyConsent: form.privacyConsent,
			marketingConsent: form.marketingConsent,
			...(form.creditCheckPreference && {
				creditCheckPreference: form.creditCheckPreference,
			}),
			...(form.residencyStatus && {
				residencyStatus: form.residencyStatus.value,
			}),
			...(form.visaSubclass && {
				visaSubclassNumber: form.visaSubclass.toString(),
			}),
			...(typeof form.expectPRInThreeMonths === 'string' && {
				expectPRInThreeMonths: form.expectPRInThreeMonths === 'true'
			}),
			hasInvalidVisa: form.hasInvalidVisa,
			...(isJoint && form.maritalStatus && {
				maritalStatus: form.maritalStatus.value,
			}),
		};

		return payload as UpdateDetailsRequest;
	}
);
