import * as Sentry from "@sentry/react";
import config from "../config";

Sentry.init({
	dsn: config.VITE_SENTRY_DSN,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.httpClientIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			maskAllInputs: true,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: config.VITE_SENTRY_TRACES_SAMPLE_RATE,
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets:
		config.VITE_SENTRY_TRACE_PROPAGATION_TARGETS.split(","),
	// Session Replay
	replaysSessionSampleRate: config.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
	replaysOnErrorSampleRate: config.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
	environment: config.VITE_APP_ENVIRONMENT,
	enabled: ["production"].includes(config.VITE_APP_ENVIRONMENT),
	sendDefaultPii: true,
});

export const setSentryContext = ({
	userId,
	applicationId,
}: {
	userId: string;
	applicationId: string;
}) => {
	Sentry.setUser({ id: userId });
	Sentry.setTag("applicationId", applicationId);
};

export const resetSentryContext = () => {
	Sentry.setUser(null);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureException = (error: Error, hint?: any) => {
	if (config.VITE_APP_ENVIRONMENT === "local") {
		console.error('captureException', error, hint);
	} else {

		Sentry.captureException(error, hint);
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureMessage = (message: string, hint?: any) => {
	if (config.VITE_APP_ENVIRONMENT === "local") {
		console.error('captureMessage', message, hint);
	} else {

		Sentry.captureMessage(message, hint);
	}
};
