import type { CreateExpense } from "@sucasa-finance/origination-trpc";
import type { UpdateExpensesRequest, UpdateExpensesResponse, UpdateExpensesResponseToBeMapped } from "../../update-expenses.schema";
import { expenseNameMapping, isUpdateExpensesRequestKey } from "./enum-mapping";
import { emptyOkResponseData } from "./response";
import { selectFormApplicationId } from "../../../../store/slices/application-slice";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { useCallback } from "react";
import { mapStepAndSubStep } from "./step";

export function mapUpdateExpensesRequest(request: UpdateExpensesRequest, applicationId: number | string) {
    const expenses = Object.keys(request)
        .filter(isUpdateExpensesRequestKey)
        .map((key): CreateExpense => {
            const name = expenseNameMapping[key];
            if (!name) {
                throw new Error(`Expected name mapping for expense "${key}"`);
            }
            return {
                name,
                value: Number(request[key] ?? 0),
            }
        });
    return {
        expenses,
        applicationId: Number(applicationId),
        ...mapStepAndSubStep(request),
        ...(request.lowExpensesExplanation ? { lowExpensesExplanation: request.lowExpensesExplanation } : {}),
        ...(request.otherExpensesExplanation ? { otherExpensesExplanation: request.otherExpensesExplanation } : {}),
        ...(request.childcareExpensesExplanation ? { childcareExpensesExplanation: request.childcareExpensesExplanation } : {}),
        ...(request.educationExpensesExplanation ? { educationExpensesExplanation: request.educationExpensesExplanation } : {}),
    };
}

export function useMapUpdateExpensesRequest() {
	const applicationId = useAppSelector(selectFormApplicationId);
    return useCallback((request: UpdateExpensesRequest) => mapUpdateExpensesRequest(request, applicationId), [applicationId]);
}

export function mapUpdateExpensesResponse(response: UpdateExpensesResponseToBeMapped): UpdateExpensesResponse {
    const empty = emptyOkResponseData();
    return {
        data: {
            ...empty,
            content: {
                ...empty.content,
                expensesSeemLow: response.status === 'Seems Low'
            }
        }
    }
}
