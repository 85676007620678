import { type ReactNode, useMemo, useState } from "react";
import { httpLink } from "@trpc/client";
import superjson from "superjson";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { origination } from "../services/origination";
import env from "../config";
import { useAppSelector } from "./redux-hooks";
import { selectAccessToken } from "../store/slices/auth-slice";
import { useOnResponseStatus } from "./use-response-status";
import { captureMessage } from "../services/sentry";

export const TrpcProvider = ({ children }: { children: ReactNode }) => {
  const accessToken = useAppSelector(selectAccessToken);
  const [queryClient] = useState(() => new QueryClient());
  const onResponseStatus = useOnResponseStatus();

  const originationClient = useMemo(() => {
    return origination.createClient({
      links: env.VITE_ORIGINATION_API
        ? [
          httpLink({
            url: `${env.VITE_ORIGINATION_API}trpc/`,
            transformer: superjson,
            async fetch(url, init) {
              try {
                const response = await fetch(url, init)
                await onResponseStatus(response, String(url), init);
                return response;
              } catch (error) {
                // capture CORS errors
                if ((error as Error)?.message === 'Load failed') {
                  captureMessage(`Load failed error.`, {
                    error,
                    extra: {url, init}
                  });
                }
                throw error;
              }
            },
            headers: () => {
              return {
                "Content-Type": "application/json",
                ...(accessToken ? {'Authorization': `Bearer ${accessToken}`} : {})
              };
            },
          })
        ]
        : [],
      });
  }, [accessToken, onResponseStatus]);

  return (
    <origination.Provider client={originationClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
    </origination.Provider>
  );
};
